<template>
  <div ref="editor" class="codemirror"></div>
</template>

<script>
import { basicSetup, EditorView } from 'codemirror';
import { EditorState, Compartment } from '@codemirror/state';
import { javascript } from '@codemirror/lang-javascript'; // Замените на нужный вам язык

export default {
  name: 'CodeMirrorComponent',
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      view: null,
      language: new Compartment(),
      tabSize: new Compartment(),
      lineWrapping: new Compartment()
    };
  },
  watch: {
    value(newVal) {
      if (this.view && this.view.state.doc.toString() !== newVal) {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: newVal }
        });
      }
    },
    options: {
      handler(newOptions) {
        this.updateExtensions(newOptions);
      },
      deep: true
    }
  },
  mounted() {
    try {
      const extensions = [
        basicSetup,
        this.language.of(javascript()), // Замените на нужный вам язык
        this.tabSize.of(EditorState.tabSize.of(4)), // Размер табуляции по умолчанию 4
        this.lineWrapping.of(this.options.lineWrapping ? EditorView.lineWrapping : []),
        EditorView.updateListener.of(update => {
          if (update.changes) {
            this.$emit('input', update.state.doc.toString());
          }
        })
      ];

      this.view = new EditorView({
        state: EditorState.create({
          doc: this.value,
          extensions: extensions
        }),
        parent: this.$refs.editor
      });

      this.updateExtensions(this.options);
    } catch (error) {
      console.error('Error initializing CodeMirror:', error);
    }
  },
  beforeDestroy() {
    if (this.view) {
      this.view.destroy();
    }
  },
  methods: {
    updateExtensions(options) {
      if (this.view) {
        const transaction = this.view.state.update({
          effects: [
            this.language.reconfigure(options.language || javascript()), // Замените на нужный вам язык
            this.tabSize.reconfigure(EditorState.tabSize.of(options.tabSize || 4)), // Размер табуляции по умолчанию 4
            this.lineWrapping.reconfigure(options.lineWrapping ? EditorView.lineWrapping : [])
          ]
        });
        this.view.dispatch(transaction);
      }
    }
  }
};
</script>

<style scoped>
.cm-editor {
  height: 100%;
  border: 1px solid #ddd;
}
</style>
