<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="container main-workflow-container" v-if="Object.keys(workflow).length > 0">
      <div class="workflow-information">
        <div><strong>Title:</strong> {{ workflow.title }};</div>
        <div><strong>Platforms:</strong> {{ workflow.platforms.join(', ') }};</div>
        <div><strong>Application:</strong> {{ workflow.application }};</div>
        <div><strong>Type:</strong> {{ workflow.type }};</div>
      </div>
      <div class="actions sticky">
        <div class="blocks-container">
          <div v-for="block in blocks" :key="block.id" class="block-item" :style="{ backgroundColor: block.color }">
            <b-button v-on:click="addToWorkflow(block)" variant="outline-primary" class="add-btn">
              <b-icon icon="plus"></b-icon>
              <span>{{ block.label }}</span>
            </b-button>
            <b-icon v-if="block.globalSettings && block.globalSettings.length > 0" icon="gear" class="settings-icon" v-on:click="toggleGlobalSettings(block.id)"></b-icon>
          </div>
        </div>
        <div class="test-button-group">
          <div class="status-count">
            <b-icon icon="check-circle" class="text-success"></b-icon> {{ successCount }}
            <b-icon icon="exclamation-circle" class="text-danger"></b-icon> {{ errorCount }}
          </div>
          <b-button v-on:click="testWorkflow()" :disabled="loading" variant="primary" class="test-btn">
            <span v-if="!loading">Test Workflow</span>
            <span v-else>Testing...</span>
          </b-button>
          <b-button v-on:click="toggleAllBlocks()" variant="outline-secondary" class="toggle-all-btn">
            <span v-if="allCollapsed">Expand All</span>
            <span v-else>Collapse All</span>
          </b-button>
          <div class="fullScreen" @click="fullscreen"><div></div><div></div></div>
        </div>
      </div>

      <div v-for="block in blocks" :key="block.id">
        <div v-if="block.showGlobalSettings" class="global-settings-panel">
          <b-form-group v-for="setting in block.globalSettings" :key="setting.key" :label="setting.label">
            <CodeMirrorComponent v-if="setting.type === 'text'" class="codemirror-json" v-model="setting.value" :options="codeMirrorOptions" />
            <b-form-input v-else-if="setting.type === 'number'" v-model="setting.value" :type="setting.type"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="workflow-wrapper">
        <draggable
          v-model="workflow.blocks"
          class="workflow-container"
          :options="{ group: 'blocks', filter: '.cm-editor' }"
        >
          <transition-group>
            <b-row v-for="(block, index) in workflow.blocks" :key="block.uniqueId" class="workflow-item">
              <b-col>
                <div class="workflow-block">
                  <div class="block-header" :style="{ background: getBlockColor(block) }">
                    <span>{{ block.label }} {{ block.title ? '(' + block.title + ')' : '' }}. Index: {{ index }}</span>
                    <div class="button-group">
                      <b-icon
                        :icon="getStatusIcon(block)"
                        :class="getStatusClass(block)"
                        class="status-icon"
                      ></b-icon>
                      <b-button v-on:click="testWorkflow(block)" variant="outline-primary" class="restart-btn">
                        <b-icon icon="arrow-clockwise"></b-icon>
                      </b-button>
                      <b-button v-on:click="removeFromWorkflow(index)" variant="outline-danger" class="remove-btn">
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                      <b-button v-on:click="toggleBlock(block)" variant="outline-secondary" class="toggle-btn">
                        <b-icon :icon="block.collapsed ? 'chevron-down' : 'chevron-up'"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="block-body" v-if="!block.collapsed">
                    <b-form-group label="Title:">
                      <CodeMirrorComponent v-model="block.title" :options="codeMirrorOptions" />
                    </b-form-group>
                    <div v-if="block.key === 'ai_block'" class="block-content">
                      <b-form-group label="Key:" v-if="block.content.mode === 'one_key_ai'">
                        <CodeMirrorComponent v-model="block.content.key" :options="codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Value:">
                        <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Mode:">
                        <b-form-radio v-model="block.content.mode" value="one_key_ai">One Key AI</b-form-radio>
                        <b-form-radio v-model="block.content.mode" value="full_data_ai">Full Data AI</b-form-radio>
                      </b-form-group>
                    </div>
                    <div v-if="block.key === 'code_block'" class="block-content">
                      <b-form-group>
                        <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="codeMirrorOptions" />
                      </b-form-group>
                    </div>
                    <div v-if="block.key === 'field_block'" class="block-content">
                      <b-form-group label="Key:">
                        <CodeMirrorComponent v-model="block.content.key" :options="codeMirrorOptions" />
                      </b-form-group>
                      <b-form-group label="Value:">
                        <CodeMirrorComponent v-model="block.content.value" :options="codeMirrorOptions" />
                      </b-form-group>
                    </div>
                    <div v-if="blockResult[block.uniqueId]" class="block-result">
                      <label>Result (Automate Data): </label>
                      <div class="result-content">
                        <div v-if="blockResult[block.uniqueId].error" class="error-message">{{ blockResult[block.uniqueId].error }}</div>
                        <pre v-else class="formatted-result">{{ formatJson(blockResult[block.uniqueId].result) }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </transition-group>
        </draggable>
        <div class="json-section sticky">
          <b-form-group label="Input JSON">
            <CodeMirrorComponent class="codemirror-json" v-model="inputJson" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Output JSON (Automate Data)">
            <CodeMirrorComponent class="codemirror-json" v-model="automateData" :options="codeMirrorOptions" />
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import draggable from 'vuedraggable'
import { mapGetters, mapMutations } from "vuex";
import CodeMirrorComponent from '@/agGridV2/components/codemirror.component.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'lead-center-workflow',
  components: {
    LeadCenterNavigation,
    draggable,
    CodeMirrorComponent
  },
  data() {
    return {
      blocks: [
        {
          id: 1,
          label: 'AI Block',
          key: 'ai_block',
          color: '#FAE7E5', // Gold
          content: {
            value: '',
            key: '',
            mode: 'one_key_ai'
          },
          showGlobalSettings: false,
          collapsed: true,
          globalSettings: [
            {
              key: 'systemPromptOneKeyAi',
              label: 'System Prompt (One Key AI)',
              value: '',
              type: 'text'
            },
            {
              key: 'systemPromptFullDataAi',
              label: 'System Prompt (Full Data AI)',
              value: '',
              type: 'text'
            },
            {
              key: 'temperature',
              label: 'Temperature',
              value: null,
              type: 'number'
            }
          ]
        },
        {
          id: 2,
          label: 'Code Block',
          key: 'code_block',
          color: '#D9E4FC', // Sky Blue
          content: {
            value: ''
          },
          collapsed: true
        },
        {
          id: 3,
          label: 'Field Block',
          key: 'field_block',
          color: '#FCF3C9', // Pale Green
          content: {
            value: '',
            key: ''
          },
          collapsed: true
        }
      ],
      workflow: {},
      blockStatus: {},
      blockResult: {},
      blocksResults: {},
      loading: false,
      inputJson: '',
      automateData: '',
      showGlobalSettings: false,
      codeMirrorOptions: {
        lineWrapping: true
      },
      isSticky: false,
      initialActionsTop: null,
      allCollapsed: true
    }
  },
  async mounted() {
    this.setViewLoader(true)
    try {
      this.workflow = await MtApi.getWorkflow(this.$route.params._id)
      if (Object.keys(this.workflow).length === 0) {
        this.$redirectTo('/lead-center/workflows')
      }
      const globalSettings = this.workflow.blocks_global_settings
      if (globalSettings && globalSettings.length > 0) {
        for (const setting of globalSettings) {
          const blockIndex = this.blocks.findIndex((b) => b.key === setting.key)
          if (blockIndex !== -1) {
            this.blocks[blockIndex].globalSettings = [ ...setting.globalSettings ]
          }
        }
      }
      const timeout = setTimeout(() => {
        this.initialActionsTop = this.$el.querySelector('.actions').offsetTop
        window.addEventListener('scroll', this.handleScroll)
        clearTimeout(timeout)
      }, 1000)
    } catch (err) {
      this.$redirectTo('/lead-center/workflows')
    }
    this.setViewLoader(false)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll) // Clean up event listener
  },
  watch: {
    workflow: {
      handler: 'createOrUpdateConfig',
      deep: true
    },
    blocks: {
      handler: 'createOrUpdateConfig',
      deep: true
    }
  },
  computed: {
    successCount() {
      return this.workflow.blocks.filter((block) => this.blockStatus[block.uniqueId] === 'success').length
    },
    errorCount() {
      return this.workflow.blocks.filter((block) => this.blockStatus[block.uniqueId] === 'error').length
    },
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      }
    }
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    ...mapMutations(['setViewLoader']),
    handleScroll() {
      this.isSticky = window.scrollY > this.initialActionsTop
    },
    async createOrUpdateConfig() {
      const blocks_global_settings = [...this.workflow.blocks_global_settings]
      for (const block of this.blocks) {
        const blockIndex = blocks_global_settings.findIndex((b) => b.key === block.key)
        if (blockIndex !== -1) {
          blocks_global_settings[blockIndex] = {
            key: block.key,
            globalSettings: block.globalSettings
          }
        } else {
          blocks_global_settings.push({
            key: block.key,
            globalSettings: block.globalSettings
          })
        }
      }
      await MtApi.createOrUpdateWorkflow({
        _id: this.workflow._id,
        blocks: this.workflow.blocks,
        blocks_global_settings: blocks_global_settings
      })
    },
    async addToWorkflow(block) {
      const newBlock = {
        ...block,
        uniqueId: uuidv4(),
        content: {
          ...JSON.parse(JSON.stringify(block.content))
        },
        showSettings: false,
        collapsed: true
      }
      this.workflow.blocks.push(newBlock)
    },
    async removeFromWorkflow(index) {
      this.workflow.blocks.splice(index, 1)
    },
    async testWorkflow(singleBlock = null) {
      this.loading = true
      if (singleBlock) {
        this.resetStatuses(singleBlock)
      } else {
        this.blocksResults = {}
        this.resetStatuses()
      }

      let activity_data
      try {
        activity_data = JSON.parse(this.inputJson)
      } catch (e) {
        alert('Invalid JSON input')
        this.loading = false
        return
      }

      const blocksToTest = singleBlock ? [singleBlock] : this.workflow.blocks

      let blockAnswer = null
      for (let i = 0; i < blocksToTest.length; i++) {
        if (
          blockAnswer &&
          blockAnswer['skipBlocks'] &&
          blockAnswer['skipBlocks'].includes(i)
        ) {
          continue
        }
        const block = blocksToTest[i]
        this.$set(this.blockStatus, block.uniqueId, 'testing')
        try {
          blockAnswer = await this.processBlock(
            activity_data,
            Object.assign({}, ...Object.values(this.blocksResults)),
            block
          )
          this.$set(this.blockStatus, block.uniqueId, 'success')
          if (
            blockAnswer &&
            blockAnswer['break']
          ) {
            delete blockAnswer['break']
            this.blocksResults[block.uniqueId] = {
              ...blockAnswer
            }
            break
          }
          this.blocksResults[block.uniqueId] = blockAnswer
          this.$set(this.blockResult, block.uniqueId, { result: blockAnswer })
        } catch (error) {
          this.$set(this.blockStatus, block.uniqueId, 'error')
          this.$set(this.blockResult, block.uniqueId, { error: error.response ? error.response.data.message : error.message })
        }
      }
      this.automateData = JSON.stringify(
        Object.assign({}, ...Object.values(this.blocksResults)),
        null,
        2
      )
      this.loading = false
    },
    resetStatuses(block = null) {
      if (block) {
        this.$set(this.blockStatus, block.uniqueId, null)
        this.$set(this.blockResult, block.uniqueId, null)
      } else {
        this.workflow.blocks.forEach((block) => {
          this.$set(this.blockStatus, block.uniqueId, null)
          this.$set(this.blockResult, block.uniqueId, null)
        })
      }
    },
    async processBlock(activity_data, automate_data, block) {
      const settings = this.workflow.blocks_global_settings.find((b) => b.key === block.key)

      return MtApi.workflowProcess({
        activity_data: activity_data,
        automate_data: automate_data,
        platform: this.selectedPlatform._id,
        block: {
          content: block.content,
          key: block.key,
          globalSettings: settings ? settings.globalSettings : []
        }
      })
    },
    toggleGlobalSettings(block_id) {
      const blockIndex = this.blocks.findIndex((b) => b.id === block_id)
      this.$set(this.blocks[blockIndex], 'showGlobalSettings', !this.blocks[blockIndex].showGlobalSettings)
    },
    toggleBlock(block) {
      this.$set(block, 'collapsed', !block.collapsed)
    },
    toggleAllBlocks() {
      this.allCollapsed = !this.allCollapsed
      this.workflow.blocks.forEach(block => {
        this.$set(block, 'collapsed', this.allCollapsed)
      })
    },
    getStatusIcon(block) {
      const status = this.blockStatus[block.uniqueId]
      switch (status) {
        case 'testing':
          return 'clock'
        case 'success':
          return 'check-circle'
        case 'error':
          return 'exclamation-circle'
        default:
          return 'circle'
      }
    },
    getStatusClass(block) {
      const status = this.blockStatus[block.uniqueId]
      switch (status) {
        case 'testing':
          return 'text-primary'
        case 'success':
          return 'text-success'
        case 'error':
          return 'text-danger'
        default:
          return 'text-secondary'
      }
    },
    formatJson(json) {
      return JSON.stringify(json, null, 2).trim()
    },
    getBlockColor(block) {
      const originalBlock = this.blocks.find(b => b.key === block.key)
      return originalBlock ? originalBlock.color : '#FFFFFF'
    },
    fullscreen(event, init = false) {
      const toggleFullScreen = () => {
        this.$store.dispatch('toggleFullScreen', { page: this.$route.name })
        this.updateFullScreenClass()
      }

      const keyClose = () => {
        document.addEventListener('keydown', event => {
          if (event.code === 'Escape') {
            const isModal = document.querySelector('.vm--modal');
            if (!isModal) {
              toggleFullScreen()
            }
          }
        }, { once: true });
      }

      if (init) {
        const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
        if (isFullScreen) {
          this.updateFullScreenClass()
          keyClose()
        }
      } else {
        toggleFullScreen()
        keyClose()
      }
    },
    updateFullScreenClass() {
      const elements = document.getElementsByClassName('main-workflow-container')
      const isFullScreen = this.$store.getters.isFullScreen(this.$route.name)
      Array.from(elements).forEach((element) => {
        element.classList.toggle('main-workflow-container-fullScreen', isFullScreen)
      })
    },
  }
}
</script>
<style lang="scss">
.main-workflow-container {
  margin-bottom: 50px;

  .workflow-wrapper {
    display: flex;
    gap: 20px;
  }

  .blocks-container {
    display: flex;
    .block-item {
      display: inline-flex;
      position: relative;
      margin-right: 25px;
      button {
        border: 0;
        color: #000000;
        border-radius: 0;
      }
    }
  }

  .workflow-container {
    flex: 70%;
    width: 70%;
    border: 2px dashed #007bff;
    padding: 20px;
    border-radius: 4px;
    min-height: 200px;
    background-color: #f9f9f9;
    position: relative;
    pointer-events: none; /* Prevent drag and drop */
  }

  .workflow-item {
    margin-bottom: 10px;
  }

  .workflow-block {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: grab;
    pointer-events: auto; /* Enable interactions within the block */
    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .workflow-block:active {
    cursor: grabbing;
  }

  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000; /* Set header text color to white for contrast */
    background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(0,0,0,0.1)); /* Apply gradient */
    padding: 5px 10px;
    border-radius: 0;
  }

  .block-body {
    margin-top: 10px;
    background-color: #fff; /* Keep body white */
    padding: 10px;
    border-radius: 0 0 4px 4px;
  }

  .button-group {
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      border-radius: 0;
    }
    button:not(:hover) {
      background-color: #ffffff;
    }
  }

  .codemirror .cm-editor {
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    cursor: text;
  }

  .codemirror.codemirror-json .cm-editor {
    height: 215px;
  }

  .json-section {
    flex: 30%;
    width: 30%;
    padding: 10px;
    border: 2px dashed #007bff;
    border-radius: 4px;
    height: 580px;
    overflow-y: auto;
    background-color: #f9f9f9;
    position: sticky;
    top: 20px; /* Adjust the value as needed */
    z-index: 9; /* Ensure it's below the .actions section */
  }

  .json-section.sticky {
    top: 80px;
  }

  .status-icon {
    font-size: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px; /* Add padding */
    transition: background-color 0.3s, border 0.3s;
    border: 2px dashed #007bff; /* Ensure wide border */
    margin-bottom: 20px;
  }

  .status-count {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
    font-size: 1.2rem;
  }

  .workflow-wrapper {
    position: relative;
  }

  .block-result {
    margin: 12px;
    pointer-events: auto; /* Enable interactions within the result block */
    .result-content {
      background-color: #f8f9fa;
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 10px;
      .formatted-result {
        white-space: pre-wrap;
        word-break: break-word;
      }
      .error-message {
        color: #dc3545;
        font-weight: bold;
      }
    }
  }

  .test-button-group {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .settings-icon {
    cursor: pointer;
    top: 0;
    right: -17px;
    position: absolute;
    transform: translateY(-50%);
  }

  .global-settings-panel {
    margin-bottom: 20px;
    padding: 20px;
    border: 2px dashed #007bff;
    border-radius: 4px;
    background-color: #f8f9fa;
    .form-group {
      padding: 0;
      input {
        padding: 0 10px 0 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }
  }

  /* Styles for sticky state */
  .sticky {
    background-color: #ffffff;
    border: 2px dashed #007bff; /* Ensure wide border */
    position: sticky;
    top: 0; /* Stick to the top of the container */
    z-index: 10;
  }

  .fullScreen {
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 18px;
    height: 18px;
    margin-right: 10px;

    position: relative;

    div {
      width: 100%;
      height: 100%;

      position: absolute;

      &::before,
      &::after {
        content: '';

        width: 5px;
        height: 5px;

        position: absolute;

        transition: 300ms ease;
      }

      &::before {
        border-left: 2px solid #7987FF;

        left: 0;
      }

      &::after {
        border-right: 2px solid #7987FF;

        right: 0;
      }

      &:first-child {
        &::before,
        &::after {
          border-top: 2px solid #7987FF;

          top: 0;
        }

        &::before {
          border-radius: 3px 0 0 0;
        }

        &::after {
          border-radius: 0 3px 0 0;
        }
      }

      &:last-child {
        &::before,
        &::after {
          border-bottom: 2px solid #7987FF;

          bottom: 0;
        }

        &::before {
          border-radius: 0 0 0 3px;
        }

        &::after {
          border-radius: 0 0 3px 0;
        }
      }
    }
  }
  .fullScreen:hover {
    div {
      &::before,
      &::after {
        width: 7px;
        height: 7px;
      }
    }
  }

  .main-workflow-container-fullScreen {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100% !important;
    max-width: initial !important;
    height: auto !important;
    margin: 0 !important;
    padding-top: 15px;
    padding-bottom: 75px !important;
  }
}

.workflow-information {
  border: 2px dashed #007bff;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}
</style>
